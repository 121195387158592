import Vue from 'vue';
import Router from 'vue-router';
import Env from '@/env';

Vue.use(Router);
export default new Router({
  mode: 'history',
  routes: [
    { 
      path: '/', 
      redirect: `/${Env.defaultBusinessSlug}/${Env.defaultLocationSlug}`
    },
    {
      path: '/:business_slug/:location_slug',
      component: () => import('@/views/LandingPage.vue'),
      name: 'LandingPage',
      navText: 'LandingPage',
    },
    {
      path: '*',
      name: 'PageNotFound',
      redirect: `/${Env.defaultBusinessSlug}/${Env.defaultLocationSlug}`
    },

    
  ],
});