module.exports = {
  //Business
  defaultBusinessSlug: process.env.VUE_APP_DEFAULT_BUSINESS_SLUG,
  businessProfileUrl: process.env.VUE_APP_BUSINESS_PROFILE_URL,
  businessProfileApiToken: process.env.VUE_APP_BUSINESS_PROFILE_API_TOKEN,

  // Location
  defaultLocationSlug: process.env.VUE_APP_DEFAULT_LOCATION_SLUG,
  locationUrl: process.env.VUE_APP_LOCATION_URL,
  locationApiToken: process.env.VUE_APP_LOCATION_API_TOKEN,
  locationAuthToken: process.env.VUE_APP_LOCATION_AUTH_TOKEN,

  // Checkin
  checkinUrl: process.env.VUE_APP_CHECKIN_URL,
}