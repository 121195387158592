import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import spinner from '@/store/modules/spinner';

const initialState = {
  spinner: spinner.state
};

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    spinner,
  },
  plugins: [
    createPersistedState(),
  ],
  mutations: {

  },
  actions: {
   
  },
})