<template>
  <div id="app">
     <router-view/>
     <spinner></spinner>
  </div>
</template>

<script>
import Spinner from './components/helpers/Spinner.vue'
export default {
  name: 'App',
  components: {
    Spinner
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    // on mount hit api and fetch business

  },
  methods: {
  
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
