export default {
  namespaced: true,
  state: {
    active: false,
  },
  getters: {
    active: state => state.active,
  },
  mutations: {
    updateSpinnerState(state, data) {
      state.active = data;
    },
  },
  actions: {
    setSpinner(context, data) {
      context.commit('updateSpinnerState', data);
    },
  },
};
